import { Alert, Snackbar, useMediaQuery, useTheme } from "@mui/material";

import MobileGridHeader from "./MobileGridHeader";
import DesktopGridHeader from "./DesktopGridHeader";
import SelectResponsiblePerson from "../../Survey/components/SelectResponsiblePerson";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";
import { updateResponsiblePersonForRepairRequests } from "../../../apis/aftercareApis";
import { apps } from "../../../_constants";

const CustomToolbar = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  setIsOpenObjectModal,
  isOpenObjectModal,
  handleSearch,
  searchText,
  quickFilterItems,
  handleExportModal,
  selectedRows,
  isAnyRepairRequestCompleted
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [organizationsLoading, setOrganizationsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "error"
  })
  const [taskAnchorEl, setTaskAnchorEl] = useState(null);
  const [responsiblePopupAnchorEl, setResponsiblePopupAnchorEl] = useState(null);
  const isTaskMenuOpen = Boolean(taskAnchorEl);
  const showAddBtn = app === apps.aftercare

  const handleTaskMenuOpen = (event) => {
    setTaskAnchorEl(event.currentTarget);
  };
  const handleTaskMenuClose = () => {
    setTaskAnchorEl(null);
  };

  const handleResponsiblePerson = async (data) => {
    try {
      const response = await updateResponsiblePersonForRepairRequests(app, data.loginId, selectedRows)
      setResponsiblePopupAnchorEl(null);
      if (response.status === 200) {
        setToast({
          open: true,
          message: t("repairRequest.updateResponsiblePerson.success"),
          type: "success"
        });
      } else {
        setToast({
          open: true,
          message: t("general.failedToUpdate"),
          type: "error"
        });
      }
      setTaskAnchorEl(null);
      setIsSubmitting(false)
    } catch (error) {
      setTaskAnchorEl(null);
      setToast({
        open: true,
        message: t("general.failedToUpdate"),
        type: "error"
      });
      setResponsiblePopupAnchorEl(null);
      setIsSubmitting(false)
    }
  }


  return (
    <>
      {isTabletOrMobile ? (
        <MobileGridHeader
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          setIsOpenObjectModal={setIsOpenObjectModal}
          isOpenObjectModal={isOpenObjectModal}
          handleSearch={handleSearch}
          searchText={searchText}
          quickFilterItems={quickFilterItems}
          handleExportModal={handleExportModal}
          isAnyRepairRequestCompleted={isAnyRepairRequestCompleted}
          selectedRows={selectedRows}
          isTaskMenuOpen={isTaskMenuOpen}
          handleTaskMenuOpen={handleTaskMenuOpen}
          handleTaskMenuClose={handleTaskMenuClose}
          taskAnchorEl={taskAnchorEl}
          setResponsiblePopupAnchorEl={setResponsiblePopupAnchorEl}
          showAddBtn={showAddBtn}
        />
      ) : (
        <DesktopGridHeader
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          setIsOpenObjectModal={setIsOpenObjectModal}
          isOpenObjectModal={isOpenObjectModal}
          handleSearch={handleSearch}
          searchText={searchText}
          quickFilterItems={quickFilterItems}
          handleExportModal={handleExportModal}
          isAnyRepairRequestCompleted={isAnyRepairRequestCompleted}
          selectedRows={selectedRows}
          isTaskMenuOpen={isTaskMenuOpen}
          handleTaskMenuOpen={handleTaskMenuOpen}
          handleTaskMenuClose={handleTaskMenuClose}
          taskAnchorEl={taskAnchorEl}
          setResponsiblePopupAnchorEl={setResponsiblePopupAnchorEl}
          showAddBtn={showAddBtn}
        />
      )}

      <SelectResponsiblePerson
        isSubmitting={isSubmitting}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        customTitle={t("general.responsible")}
        onSubmit={(values) => handleResponsiblePerson(values)}
        setLoading={(val) => {
          setOrganizationsLoading(val);
          setIsSubmitting(true)
        }}
        loading={organizationsLoading}
        forRepairRequests
        repairRequestIds={selectedRows}
        anchorEl={responsiblePopupAnchorEl}
        onClose={() => {
          setResponsiblePopupAnchorEl(null)
        }}
      />
      
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={() =>
          setToast({
            open: false,
            message: "",
            type: "error"
          })
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={toast.message}
      >
        <Alert
          onClose={() =>
            setToast({
              open: false,
              message: "",
              type: "error"
            })
          }
          severity={toast.type}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomToolbar;

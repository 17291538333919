import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CircularProgress,
  Container,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getLocalStorageItem,
  groupBy,
  repairRequestStatusValue,
  setLocalStorageItem,
} from "../../_helpers";
import { getRRDashboardCounts } from "../../apis/surveyingApis";
import { apps, LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../_constants";
import DataGridV2 from "../../components/DataGridV2";
import {
  GridToolbar
} from '@mui/x-data-grid';
import { LinkColumnV2 } from "../../components/DataColumnsV2";
import { getColumnsOrderFromLocalStorage } from "../../_helpers/getGridContextMenuList";

const Dashboard = (props) => {
  const { history } = props;
  const { t, i18n } = useTranslation();
  const [rowsData, setRowsData] = useState([]);
  const classes = useStyles();
  const { all = [], loading: buildingLoading } = useSelector(
    (state) => state.buildings
  );
  const app = useSelector((state) => state.app);
  const isAfterCareApp = app === apps.aftercare;

  const [isAfterCareEmployeeOrSiteManager, setIsAfterCareEmployeeOrSiteManager] = useState(false);
  const [filterModel, setFilterModel] = useState(() => getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
  ))
  const [sortModel, setSortModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
  ))
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_VISIBILITY[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
  ))
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.DASHBOARD_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
  ))
  const [isRowsDataLoading, setIsRowsDataLoading] = useState(false)

  useEffect(() => {
    if (
      all.every(
        (a) =>
          !a.roles ||
          a.roles.length === 0 ||
          a.roles.every(
            (b) =>
              b === userAccountRoleConstants.subContractorSupervisor ||
              b === userAccountRoleConstants.subContractorEmployee
          )
      )
    ) {
      if (app === apps.aftercare) {
        history.push("/home")
      } else if (app === apps.constructionQuality) {
        history.push("/kwaliteitsborging");
      }
    } else
      setIsAfterCareEmployeeOrSiteManager(
        all.some(
          (a) =>
            a.roles &&
            (
              a.roles.includes(userAccountRoleConstants.aftercareEmployee) ||
              a.roles.includes(userAccountRoleConstants.siteManager)
            )
        )
      );
  }, [all]);

  useEffect(() => {
    setIsRowsDataLoading(true)
    const projectsGroup = groupBy(all, "projectId");
    const projects = Object.keys(projectsGroup);
    if (!buildingLoading) {
      (async () => {
        try {
          const allProjects = (await getRRDashboardCounts(app, projects)).data ?? [];
          const dashboardRowsData = [];
          for (let i = 0; i < allProjects?.length; i++) {
            const singleProject = allProjects[i];
            const { new: newRepairRequests, projectId, projectNo, projectName, noOfObjects, inProgress, overDue, workOrdersOverdue, type, constructionType, phase, city, workOrdersInProgress } = singleProject || {}

            dashboardRowsData.push({
              id: projectId,
              objects: noOfObjects,
              projectName,
              newRepairRequests: newRepairRequests,
              address: city,
              inProgressRepairRequests: inProgress,
              overdueRepairRequests: overDue,
              openWorkOrders: workOrdersInProgress,
              overdueWorkOrders: workOrdersOverdue,
              projectId,
              type: t(`project.type.${type}`),
              constructionType: t(`project.constructionType.${type}`),
              phase,
              projectNo
            });
          }
          setRowsData(dashboardRowsData)
          setIsRowsDataLoading(false)
        } catch (err) {
          console.error(err);
          setIsRowsDataLoading(false)
        }
      })();
    }
    return () => {
      setRowsData([]);
    };
  }, [history.location.key, all, app, buildingLoading, i18n.language]);

  const getSelectedProjectAndPathname = (projectId, isWorkOrderColumnClicked = false) => {
    const selectedProject = all.filter(
      (x) => x.projectId === projectId
    )[0];
    let pathname = "/nazorg"
    if (app === apps.aftercare) {
      pathname += isWorkOrderColumnClicked ? "/werkbonnen" : "/meldingen";
    } else if (app === apps.constructionQuality) {
      pathname = `/werk/${selectedProject.projectNo}` + (isWorkOrderColumnClicked ? "/werkbonnen" : "/kwaliteitsborging");
    }
    return { selectedProject, pathname }
  }

  const columns = useMemo(() => {
    const columns = [
      {
        field: "projectNo",
        headerName: t("general.projectNumber"),
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const rowData = params.row;
          return <LinkColumnV2 label={rowData.projectNo} to={`dashboard/${rowData.projectId}`} />
        },
      },
      {
        field: "projectName",
        headerName: t("general.projectName"),
        flex: 1,
        filterable: true,
        renderCell: (params) => {
          const rowData = params.row;
          return <LinkColumnV2 label={rowData.projectName} to={`/dashboard/${rowData.projectId}`} />
        },
      },
      {
        field: "address",
        headerName: t("general.address.city"),
        flex: 1,
        filterable: true,
      },
      {
        field: "objects",
        headerName: t("general.objects"),
        flex: 1,
        filterable: false,
      },
      {
        field: "newRepairRequests",
        headerName: t("site.manager.new.repairrequests"),
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return <LinkColumnV2 label={rowData.newRepairRequests} to={{ pathname, state: { filter: "newRepairRequests", selectedProject, status: [repairRequestStatusValue.NEW] } }} />
        },
      },
      {
        field: "inProgressRepairRequests",
        headerName: t("site.manager.inprogress.repairrequests"),
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return <LinkColumnV2 label={rowData.inProgressRepairRequests} to={{ pathname, state: { filter: "inProgressRepairRequests", selectedProject, status: [repairRequestStatusValue.IN_PROGRESS] } }} />
        },
      },
      {
        field: "overdueRepairRequests",
        headerName: t("site.manager.overdue.repairrequests"),
        flex: 1,
        filterable: false,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return <LinkColumnV2 color="error" label={rowData.overdueRepairRequests} to={{ pathname, state: { filter: "overdueRepairRequests", selectedProject, isOverDue: true } }} />
        },
      },
      {
        field: "openWorkOrders",
        headerName: t("site.manager.open.work.orders"),
        flex: 1,
        filterable: false,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId, true)
          return <LinkColumnV2 label={rowData.openWorkOrders} to={{ pathname, state: { filter: "openWorkOrders", selectedProject } }} />
        },

      },
      {
        field: "overdueWorkOrders",
        headerName: t("site.manager.overdue.work.orders"),
        flex: 1,
        filterable: false,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId, true)
          return <LinkColumnV2 color="error" label={rowData.overdueWorkOrders} to={{ pathname, state: { filter: "overdueWorkOrders", selectedProject } }} />
        },
      },
      {
        field: "type",
        headerName: t("general.type"),
        flex: 1,
        filterable: false
      },
      {
        field: "constructionType",
        headerName: t("general.project.constructionType"),
        flex: 1,
        filterable: false,
      },
      {
        field: "phase",
        headerName: t("general.phase"),
        flex: 1,
        filterable: false,
      }
    ];

    let filterColumns = getColumnsOrderFromLocalStorage({ columns, localStorageKey: LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_ORDER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"] });
    const localColumnWidth = getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_WIDTH[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], [])

    localColumnWidth.forEach((item) => {
      const columnIndex = filterColumns?.findIndex((filterItem) => filterItem.field === item.field)
      if (columnIndex !== -1) {
        filterColumns[columnIndex].width = item.width
        delete filterColumns[columnIndex].minWidth
        delete filterColumns[columnIndex].flex
      }
    });
    return filterColumns;
  }, [getSelectedProjectAndPathname, isAfterCareApp, isAfterCareEmployeeOrSiteManager, t])

  const onFilterModelChange = useCallback((newFilterModel) => {
    setFilterModel(newFilterModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
      newFilterModel
    );
  }, [isAfterCareApp])

  const onSortModelChange = useCallback((newSortModel) => {
    setSortModel(newSortModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
      newSortModel
    );
  }, [isAfterCareApp])

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.DASHBOARD_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
      newPaginationModel
    );
  }, [isAfterCareApp])

  const onColumnVisibilityModelChange = useCallback((newColumnVisibilityModel) => {
    setColumnVisibilityModel(newColumnVisibilityModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_VISIBILITY[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
      newColumnVisibilityModel
    );
  }, [isAfterCareApp])

  const onColumnOrderChange = useCallback((params) => {
    const mapValue = columns.map((column) => column.field);
    const currentOrder = [...mapValue]
    const element = currentOrder.splice(params.oldIndex - 1, 1)[0];
    currentOrder.splice(params.targetIndex - 1, 0, element);
    setLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_ORDER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], currentOrder)
  }, [columns, isAfterCareApp])

  const onColumnWidthChange = useCallback((params) => {
    const localColumnWidth = getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_WIDTH[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], [])
    const columnIndex = localColumnWidth.findIndex(item => item.columnName === params.colDef.field);
    if (columnIndex !== -1) {
      localColumnWidth[columnIndex].width = params.width;
    } else {
      localColumnWidth.push({ field: params.colDef.field, width: params.width });
    }
    setLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_WIDTH[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], localColumnWidth);
  }, [isAfterCareApp])

  if (buildingLoading)
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={25} />
      </Stack>
    );

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        loading={buildingLoading || isRowsDataLoading}
        checkboxSelection
        rows={rowsData}
        columns={columns.filter(c => !c?.hidden)}
        pagination
        {...(!!filterModel && { filterModel })}
        {...(!!sortModel && { sortModel })}
        {...(!!paginationModel && { paginationModel })}
        {...(!!columnVisibilityModel && { columnVisibilityModel })}
        onFilterModelChange={onFilterModelChange}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnWidthChange={onColumnWidthChange}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));

export { Dashboard };

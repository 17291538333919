
import { useCallback, useMemo, useState } from 'react'
import { Box, Tab, Typography, Tabs, useTheme, Tooltip, Badge, IconButton, Button, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { NotificationsNone, Message } from '@mui/icons-material';
import Activities from './Activities';
import { useTranslation } from "react-i18next";
import RepairRequestMessages from '../Chat/RepairRequestMessages';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import useChatMessages from '../Chat/hooks/useChatMessages';
import { checkHasAdminRightsForChat } from '../Chat/utils';
import { useSelector } from 'react-redux';
import { addNewRepairRequestChat } from '../../apis/aftercareApis';


const TabPanel = ({ children, value, index, icon, label, actionItem }) => {
    const theme = useTheme()
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        {
                            icon && label && <Typography component="h2" variant="h6" padding={theme.spacing(2)}>
                                {icon}&nbsp;&nbsp;
                                {label}
                            </Typography>
                        }
                        {actionItem}
                    </Box>
                    {children}
                </>
            )}
        </div>
    );
}

const NotificationPanel = ({ allActivity, generateWorkOrderPageLink, repairRequestId, resolverId, chatId, isResponseRequiredBtnVisible, hideWorkOrderLink, refreshParentState, canInitiateChat, readOnlyChat }) => {
    const { t } = useTranslation();
    const { app, buildings } = useSelector(state => state)
    const { chatProps, chatProps: { selectedChat }, updateParentState, updateChatLock, updateResponseRequired } = useChatMessages({ chatId })
    const [activeTab, setActiveTab] = useState(chatId || canInitiateChat ? 0 : 1);
    const handleActiveTab = (event, newTab) => {
        setActiveTab(newTab);
    };
    const theme = useTheme()
    const isReadOnlyChat = useMemo(() => selectedChat?.archived || selectedChat?.isLocked, [selectedChat])

    const handleLock = useCallback((e) => {
        e.stopPropagation();
        updateChatLock(!selectedChat?.isLocked)
    }, [isReadOnlyChat, selectedChat])

    const lockIconProps = useMemo(() => {
        const props = { tooltip: "", disabled: true }
        const hasAdminRightsForChat = checkHasAdminRightsForChat({ app, buildings, buildingId: selectedChat?.buildingId, projectId: selectedChat?.projectId });

        if (hasAdminRightsForChat && !selectedChat?.archived) {
            props.tooltip = selectedChat?.isLocked ? t("repairRequest.chat.clickToUnLock") : t("repairRequest.chat.clickToLock")
            props.disabled = false
        } else {
            props.tooltip = selectedChat?.isLocked ? t("survey.filter.locked") : t("survey.unLock")
        }

        return props
    }, [app, buildings, selectedChat])

    const handleInitiateChat = async () => {
        try {
            const response = await addNewRepairRequestChat({
                repairRequestId, resolverId
            });
            const responseData = response.data;
            if(responseData?.chatId){
                refreshParentState?.()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {
                (chatId || canInitiateChat) && (
                    <Box borderBottom={1} borderColor="divider"  >
                        <Tabs value={activeTab} onChange={handleActiveTab}>
                            <Tab label={<Badge badgeContent={selectedChat?.unreadMessagesCount} color='primary'>{t("chat.messages")}&nbsp;&nbsp;&nbsp;</Badge>} />
                            <Tab label={t("repairRequest.activity")} />
                        </Tabs>
                    </Box>
                )
            }
            <TabPanel
                value={activeTab}
                index={0}
                {...(!!chatId && {
                    icon: <Message color='primary' sx={{ fontSize: 30 }} />,
                    label: t("chat.messages"),
                    actionItem: <Tooltip title={lockIconProps.tooltip}>
                        <IconButton onClick={handleLock} disabled={lockIconProps.disabled}> {selectedChat?.isLocked ? <LockIcon /> : <LockOpenIcon />} </IconButton>
                    </Tooltip>
                })}>
                {(!chatId && canInitiateChat) && <Stack p={{ xs: 2.5, md: 3 }} border="1px dashed" borderColor={grey[400]}
                    borderRadius={1} width="100%" minHeight="350px" height="calc(35vh + 65px)" justifyContent="center" alignItems="center" sx={{
                        backgroundImage: 'url(/content/images/background/brickwall-bg.png)',
                    }} mt={theme.spacing(2)}>
                    <Button variant="contained" onClick={handleInitiateChat}>{t("chat.startChat")}</Button>
                </Stack>}
                {
                    chatId && <RepairRequestMessages refreshParentState={refreshParentState} chatProps={chatProps} updateParentState={updateParentState} isResponseRequiredBtnVisible={isResponseRequiredBtnVisible} updateResponseRequired={updateResponseRequired} readOnlyChat={readOnlyChat} />
                }
            </TabPanel>
            <TabPanel value={activeTab} index={1} icon={<NotificationsNone color='primary' sx={{ fontSize: 30 }} />} label={t("repairRequest.activity")}>
                <Activities chatId={chatId} repairRequestId={repairRequestId} resolverId={resolverId} allActivity={allActivity} generateWorkOrderPageLink={generateWorkOrderPageLink} hideWorkOrderLink={hideWorkOrderLink} />
            </TabPanel>
        </>
    );
}
export default NotificationPanel;
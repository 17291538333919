import { ContentCopy, FilterAlt } from "@mui/icons-material";
import { getDataTableTextLocale } from "./textHelper";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid-pro";
import { getLocalStorageItem, setLocalStorageItem } from "./storageHelper";

export const getGridContextMenuList = (args) => {
  const { handleCellSort, sortModel, handleCellFilter, filterKey, i18n, t, apiRef, getValueForFilter = () => { }, sortKey,
    getValueForCopy = (rowData, extraData) => {
      navigator.clipboard.writeText(rowData[extraData.fieldName])
    } } = args || {}
  return (innerArgs) => {
    const { sortable = true, filterable = true, copy = true, field , isQuickFilterDisplay = true} = innerArgs || {};
    const locale = getDataTableTextLocale(i18n.language);

    // Reusable onClick handler function for sorting
    const handleSortClick = ({fieldName, sortDirection}) => {
      handleCellSort && handleCellSort(sortDirection !== "unsort" ?{ field: fieldName, sort: sortDirection }: undefined, (newSortModal) => {
        setLocalStorageItem(
          sortKey,
          newSortModal
        );
      });
    };

    const ascSortItem = {
      label: locale.columnMenuSortAsc,
      icon: <GridArrowUpwardIcon fontSize="small" />,
      onClick: (record, extraData) => {
        handleSortClick({fieldName:extraData.fieldName, sortDirection:"asc"});
      },
    }

    const dscSortItem = {
      label: locale.columnMenuSortDesc,
      icon: <GridArrowDownwardIcon fontSize="small" />,
      onClick: (record, extraData) => {
        handleSortClick({fieldName: extraData.fieldName, sortDirection:"desc"});
      },
    }

    const unSortItem = {
      label: locale.columnMenuUnsort,
      onClick: (record, extraData) => {
        handleSortClick({ sortDirection: "unsort"});
      },
    }

    let sortOptions = []

    if (sortable) {
      if (sortModel?.[0]?.field === field) {
        if (sortModel?.[0]?.sort === "asc") {
          sortOptions = [dscSortItem, { ...unSortItem, isDividerAfter: true }]
        } else {
          sortOptions = [ascSortItem, { ...unSortItem, isDividerAfter: true }]
        }
      } else {
        sortOptions = [ascSortItem, { ...dscSortItem, isDividerAfter: true }]
      }
    }

    let filterOptions = []

    if(filterable){
      filterOptions.push({
        label: locale.columnMenuFilter,
        icon: <FilterAlt />,
        isDividerAfter: true,
        onClick: (_, extraData) => {
          apiRef.current.showFilterPanel(extraData.fieldName);
        },
      })

      if(isQuickFilterDisplay){
        filterOptions.push({
          label: t("general.quickFilter"),
          icon: <FilterAlt />,
          onClick: (data, extraData) => {
            let quickFilters = []
            const addFilter = ({ value, fieldId, type }) => quickFilters.push({ fieldId: fieldId || extraData.fieldName, value, operator: Array.isArray(value) && value.length > 1 ? "isAnyOf" : undefined, type: type || extraData.type })
    
            const value = getValueForFilter(data, extraData)
            addFilter({ value })
            handleCellFilter(quickFilters, (filterModal) => {
              setLocalStorageItem(
                filterKey,
                filterModal
              );
            })
          },
        })
      }
    }

    const copyOption = copy ? [{
      label: t("general.copy"),
      icon: <ContentCopy fontSize="small" />,
      onClick: (...args) => {
        const text = getValueForCopy(...args)
        navigator.clipboard.writeText(text || "")
      }
    },] : [];

    return [...sortOptions, ...filterOptions, ...copyOption];
  }

};


export const getColumnsOrderFromLocalStorage = ({columns , localStorageKey , mappingOfOldFieldToNewField = {}})=>{
  const localColumnOrder = getLocalStorageItem(localStorageKey, [])?.filter((columnName) => !!columnName)
  if (localColumnOrder.length > 0) {
    const setInOrder = localColumnOrder.map(columnKey => columns.find(column => column.field === (mappingOfOldFieldToNewField[columnKey] || columnKey) ) )?.filter((column)=>!!column);
    const orderedColumns = [...setInOrder , ...columns.filter((column)=> !setInOrder.find(orderColumn => orderColumn.field === column.field) )];
    setLocalStorageItem(localStorageKey, orderedColumns.map((column)=>column.field));
    return orderedColumns
  }
  return columns
}
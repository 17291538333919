import React, { useCallback, useEffect, useState } from 'react'
import { authHeader } from '../../../_helpers';

// TODO: nam shopuld be chnage
const useChatMessages = ({chatId}) => {
  const [chatProps, setChatProps] = useState({ selectedChat: null, importantChats: [], selectedHasUpdate: false })
  const { webApiUrl } = window.appConfig;

  const fetchChatDetails = useCallback((forceUpdate)=>{
    if(chatId){
      const url = webApiUrl + "api/Chat/GetChatDetails/" + chatId
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      fetch(url, requestOptions)
      .then(response => response.json())
      .then((findResponse) => {
        if (!chatProps.selectedChat) {
          setChatProps({ selectedChat: findResponse });
        } else {
          if (forceUpdate || (findResponse && JSON.stringify(chatProps.selectedChat) !== JSON.stringify(findResponse))) {
              setChatProps({ ...chatProps, selectedChat: findResponse, selectedHasUpdate: true });
          }
        }
      })
      .catch(error => console.error(error));
    }
  },[chatProps , chatId ])

  useEffect(() => {
    let timeInterval;
    if(chatId){
    fetchChatDetails()
    timeInterval = setInterval(() => {
      fetchChatDetails()
      }, 10000);
    }
    return () => {
      clearInterval(timeInterval)
    }
  }, [fetchChatDetails , chatId])

  const updateChatLock = useCallback((isLock)=>{
    const url = webApiUrl + "api/Chat/UpdateChatLock/" + chatId + "?isLock=" + isLock.toString() 
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions)
    .then(response => response.json())
    .then((findResponse) => {
      fetchChatDetails(true)
    })
    .catch(error => console.error(error));
  }, [chatId , fetchChatDetails])

  const updateResponseRequired = useCallback((isRequired)=>{
    const url = webApiUrl + "api/Chat/UpdateResponseRequired/" + chatId + "?isResponseRequired=" + isRequired.toString() 
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions)
    .then(response => response.json())
    .then((findResponse) => {
      fetchChatDetails(true)
    })
    .catch(error => console.error(error));
  }, [chatId , fetchChatDetails])

  const updateParentState = useCallback((obj) => {
    setChatProps({ ...chatProps, ...obj })
  }, [chatProps])

  return { chatProps, updateParentState, updateChatLock ,updateResponseRequired}

}

export default useChatMessages
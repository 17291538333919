import axios from "axios";
import { URLS } from "./urls";

let cancelTokenSource;
export const getRepairRequests = data => {
  // Cancel the previous request if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Previous request cancelled');
  }
  // Create a new cancel token source
  cancelTokenSource = axios.CancelToken.source();

  return axios.post(URLS.GET_AFTERCARE_REPAIR_REQUESTS, data, {
    cancelToken: cancelTokenSource.token
  });
};

export const getWorkOrders = data => axios.post(URLS.GET_AFTERCARE_WORK_ORDERS, data);
export const getWorkOrderDetails = (resolverId) => axios.get(URLS.GET_WORK_ORDER_DETAILS + resolverId);
export const getRepairRequestStatus = () => axios.get(URLS.GET_REPAIR_REQUESTS_STATUS);
export const createWorkorderReportsZip = data => axios.post(URLS.CREATE_WORKORDER_REPORTS_ZIP, data);
export const uploadExcelFileForImportData = (data,config) => axios.post(URLS.VALIDATE_EXCEL_FOR_IMPORT, data,config);
export const jsonIsAvailableForImport = () => axios.get(URLS.JSON_AVAILABLE_FOR_IMPORT);
export const proceedToImport = () => axios.get(URLS.PROCEED_TO_IMPORT);
export const addOrRescheduleAppointment = (data) => axios.post(URLS.ADD_OR_RESCHEDULE_WORK_ORDER_APPOINTMENT,data);
export const cancelAppointment = (data) => axios.post(URLS.CANCEL_WORK_ORDER_APPOINTMENT,data);
export const updateWorkOrderSignature = data => axios.post(URLS.UPDATE_WORK_ORDER_SIGNATURE, data);
export const addChatParticipant = (chatId, loginId) => axios.post(`${URLS.ADD_CHAT_PARTICIPANT}?chatId=${chatId}&chatParticipantLoginId=${loginId}`);
export const deleteChatParticipant = (chatId, chatParticipantId) => axios.delete(`${URLS.DELETE_CHAT_PARTICIPANT}?chatId=${chatId}&chatParticipantId=${chatParticipantId}`);
export const getAvailableResponsiblePersonsForRepairRequests = (app, repairRequestIds) => axios.post(`${URLS.GET_AVAILABLE_RESPONSIBLE_PERSONS_FOR_REPAIR_REQUESTS}?app=${app}`,repairRequestIds);
export const updateResponsiblePersonForRepairRequests = (app, responsiblePersonLoginGuid, repairRequestIds) => axios.post(`${URLS.UPDATE_RESPONSIBLE_PERSON_FOR_REPAIR_REQUESTS}?app=${app}&responsiblePersonLoginGuid=${responsiblePersonLoginGuid}`, repairRequestIds);  
export const addNewRepairRequestChat = (data) => axios.post(`${URLS.ADD_NEW_REPAIR_REQUEST_CHAT}`, data);
export const getAvailableParticipantsForChat = (chatId) => axios.get(URLS.GET_AVAILABLE_PARTICIPANTS_FOR_CHAT + chatId);
export const getProjectDetails = (projectId) => axios.get(URLS.GET_PROJECT_DETAILS + projectId);
export const getAvailableResponsiblePersonsForProject = (projectId, role) => axios.get(`${URLS.GET_AVAILABLE_RESPONSIBLE_PERSONS_FOR_PROJECT + projectId}?role=${role}`);
export const updateResponsiblePersonForProject = (projectId, responsiblePersonLoginGuid, role) => axios.post(`${URLS.UPDATE_RESPONSIBLE_PERSON_FOR_PROJECT}?projectId=${projectId}&responsiblePersonLoginGuid=${responsiblePersonLoginGuid}&role=${role}`);



import React from "react";
import { BuildingOverviewPage, HomePage } from "./HomePage";
import ConstructionQualityMapPage from "./Pages/ConstructionQuality/ConstructionQualityMapPage";
import DossierRedirect from "./Pages/Dossiers/DossierRedirect";
import { RequestedPage } from "./Pages/HousingWishes/RequestedPage";
import { HomeInternal } from "./Pages/Internal/HomeInternal";
import { ProjectOverview } from "./Pages/Internal/ProjectOverview";
import BuildQualityProjectOverview from "./Pages/Overview/ProjectOverview";
import SubContractorOverview from "./Pages/Overview/SubContractorOverview";
import { EmailLinkLists, WbProjects, ImportFilesForDataLoad } from "./Pages/Settings";
import DrawingLocationsNames from "./Pages/Settings/DrawingLocations/DrawingLocationsNames";
import Deliveries from "./Pages/Survey/Deliveries/Deliveries";
import Inspections from "./Pages/Survey/Inspections/Inspections";
import PreDeliveries from "./Pages/Survey/PreDeliveries/PreDeliveries";
import Signature from "./Pages/Survey/Signature/Signature";
import SurveyMoments from "./Pages/Survey/SurveyMoments";
import { apps, userAccountTypeConstants } from "./_constants";
import {
    ConstructionQualityPage, DocumentsPage,
    DossierDeadline, DossierDeadlineDetails, FaqPage,
    GeneralProjectInfoPage, InvolvedPartiesPage, MessagesPage,
    MyFinalOptionsPage, MyHomePage, ObjectLayout, ObjectsPage,
    ProjectDashboard, ProjectLayout, RepairRequestDetailsPage,
    RepairRequestsHomePage, RepairRequestWorkOrdersPage, ResolverGridPage,
    ResolverWorkOrderDetailsPage, SettingsLayout, StandardOptionsConfigPage,
    StandardOptionsPage, ThankYouOrderPage, ThankYouOrderToBeSignedPage,
    DossiersPage,
    ThankYouPage, WorkOrderDetailsPage,
    
} from "./Pages";
import AssignmentsGrid from "./Pages/Survey/AssignmentsGrid";
import AssignmentDetails from "./Pages/Survey/components/AssignmentDetails";
import { Dashboard, AfterCareResolversGrid, } from "./Pages/Aftercare";
import { useSelector } from "react-redux";
import Agenda from "./Pages/Agenda/Agenda";
import CreateSlot from "./Pages/Agenda/CreateSlot/CreateSlot";
import GenerationList from "./Pages/Agenda/Components/GenerationList";
import BuildingsListForGeneration from "./Pages/Agenda/Components/BuildingsListForGeneration";
import { ContractOverview} from "./Pages/ContractOverview";
import { Contract } from "./Pages/ContactDetails/Contract";
import { BuyerContract } from "./Pages/BuyerContract/BuyerContract";
import Profile from "./Pages/Profile/Profile";
import AfterCareRepairRequests from "./Pages/Aftercare/Grid/AfterCareRepairRequests";
import ProjectDetails from "./Pages/Aftercare/Grid/ProjectDetails";
import ImportCufXmlList from "./Pages/Settings/ImportCufXml/ImportCufXmlList";


export const buyerAndAfterCareRoutes = [
    {
        path: "/nazorg",
        Component: RepairRequestsHomePage,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        exact: true
    },
    {
        path: "/dashboard",
        Component: (props) => (
            <ProjectLayout {...props}>
                <Dashboard {...props} />
            </ProjectLayout>
        ),
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/dashboard/:projectId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ProjectDetails {...props} />
            </ProjectLayout>
        ),
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/agenda",
        Component: Agenda,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/nazorg/meldingen",
        Component: AfterCareRepairRequests,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        exact: true
    },
    {
        path: "/nazorg/berichten",
        Component: (props) => (
                <MessagesPage isFullWidth={true} {...props} />
        ),
        permissions: [ apps.aftercare],
        exact: true
    },
    {
        path: "/nazorg/melding/:repairRequestId",
        Component: RepairRequestWorkOrdersPage,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        exact: true
    },
    {
        path: "/nazorg/werkbonnen",
        Component: AfterCareResolversGrid,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        exact: true
    },
    {
        path: "/nazorg/opname/melding/:repairRequestId",
        Component: RepairRequestWorkOrdersPage,
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/nazorg/opname/werkbon/:resolverId",
        Component: WorkOrderDetailsPage,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        exact: true
    },
    {
        path: "/nazorg/werkbon/:resolverId",
        Component: WorkOrderDetailsPage,
        permissions: [apps.aftercare],
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        exact: true
    },
    {
        path: "/nazorg/werkbonnen/onderaannemer",
        Component: SubContractorOverview,
        forbiddenUserTypes: [userAccountTypeConstants.buyer],
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/nazorg/details/:repairRequestId",
        Component: RepairRequestDetailsPage,
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/algemene-projectinformatie",
        Component: GeneralProjectInfoPage,
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: "/mijn-woning",
        Component: MyHomePage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: "/betrokken-partijen",
        Component: InvolvedPartiesPage,
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: "/veel-gestelde-vragen",
        Component: FaqPage,
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: "/documenten",
        Component: DocumentsPage,
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: ["/:viewType(dossier)", "/:viewType(dossier)/:selectedDataId"],
        Component: DossiersPage,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        // we need to change building to objecten in future...
        path: [
            "/nazorg/:viewType(dossier|building)",
            "/nazorg/:viewType(dossier|building)/:selectedDataId",
            "/nazorg/:viewType(dossier|building)/:selectedDataId/dashboard"
        ],
        Component: DossiersPage,
        permissions: [apps.aftercare],
        exact: true
    },
    {
        path: "/home",
        Component: (props) => {
            const app = useSelector(state => state.app);
            if (app === apps.aftercare) return <HomeInternal {...props} />;
            else if (app === apps.huisinfo) return <HomePage {...props} />;
            return null;
        },
        permissions: [apps.aftercare, apps.huisinfo],
        exact: true
    },
    {
        path: "/berichten",
        Component: MessagesPage,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        // TODO: need to change this to /finance
        path: "/finance",
        Component: BuyerContract,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/keuzelijst",
        Component: StandardOptionsPage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/request-complete",
        Component: ThankYouPage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/aangevraagd",
        Component: RequestedPage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/order-complete",
        Component: ThankYouOrderPage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/order-unsigned",
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        Component: ThankYouOrderToBeSignedPage,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/mijndefinitieveopties",
        Component: MyFinalOptionsPage,
        forbiddenUserTypes: [userAccountTypeConstants.employee, userAccountTypeConstants.relation],
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true
    },
    {
        path: "/meldingen/map",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ConstructionQualityMapPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo, apps.aftercare],
        exact: true,
        accessInOffline: true
    },
    {
        path: "/profile/:profileType",
        Component: Profile,
        permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality, apps.resolverModule, apps.settingModule],
        exact: true
    },
    ...opnameAppRoutes("/nazorg/opname"),

];

export const layoutInternalRoutes = [
    {
        path: "/home",
        Component: HomeInternal,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/kwaliteitsborging",
        Component: HomeInternal,
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/werkbonnen",
        Component: HomeInternal,
        permissions: [apps.resolverModule],
        exact: true
    },
    {
        path: "/werk/:projectNo/kwaliteitsborging",
        Component: (props) => (
            <ProjectLayout {...props}>
                {/* FIXME: remove this file, once folder resturcturing done */}
                {/* <ConstructionQualityPage {...props} /> */}
                <AfterCareRepairRequests {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/dashboard",
        Component: (props) => (
            <ProjectLayout {...props}>
                {/* FIXME: remove this file, once folder resturcturing done */}
                {/* <BuildQualityProjectOverview {...props} /> */}
                <Dashboard {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/dashboard/:projectId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ProjectDetails {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/agenda",
        Component: Agenda,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/agenda/tijdsloten",
        Component: GenerationList,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/agenda/tijdsloten/:generationId",
        Component: BuildingsListForGeneration,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/agenda/maak",
        Component: CreateSlot,
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/werk/:projectNo/kwaliteitsborging/:repairRequestId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <RepairRequestWorkOrdersPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: ["/werk/:projectNo/kwaliteitsborging/meldingen/map", "/werk/:projectNo/meldingen/map"],
        Component: (props) => (
            <ProjectLayout {...props}>
                <ConstructionQualityMapPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality, apps.huisinfo, apps.resolverModule],
        exact: true,
        accessInOffline: true
    },
    {
        path: [
            "/werk/:projectNo/kwaliteitsborging/dossiers/:viewType(dossier|building)",
            "/werk/:projectNo/kwaliteitsborging/dossiers/:viewType(dossier|building)/:selectedDataId",
        ],
        Component: DossiersPage,
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/werk/:projectNo/kwaliteitsborging/opname/melding/:repairRequestId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <RepairRequestWorkOrdersPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    {
        path: "/werk/:projectNo/kwaliteitsborging/opname/werkbon/:resolverId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <WorkOrderDetailsPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality],
        exact: true
    },
    ...opnameAppRoutes("/werk/:projectNo/kwaliteitsborging/opname", "/werk/:projectNo/opname"),
    {
        path: "/werk/:projectNo/werkbon/:resolverId",
        Component: (props) => (
            <ProjectLayout {...props}>
                {props.app === apps.constructionQuality ?
                    <WorkOrderDetailsPage {...props} /> : <ResolverWorkOrderDetailsPage {...props} />}
            </ProjectLayout>
        ),
        permissions: [apps.constructionQuality, apps.resolverModule],
        exact: true
    },
    {
        path: "/werk/:projectNo/werkbonnen",
        Component: (props) => (<>
            {props.app === apps.constructionQuality ? <ProjectLayout {...props}>
                <ResolverGridPage {...props} />
            </ProjectLayout> : <AfterCareResolversGrid {...props}/>} 
            </>
        ),
        permissions: [apps.resolverModule, apps.constructionQuality],
        exact: true
    },
    {
        path: "/werk/:projectNo/werkbonnen/onderaannemer",
        Component: (props) => (
            <ProjectLayout {...props}>
                <SubContractorOverview {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.resolverModule, apps.constructionQuality],
        exact: true
    },
    {
        path: "/werk/:projectNo",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ProjectDashboard {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/werk/:projectNo/overzicht",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ProjectOverview {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/werk/:projectNo/objecten",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ObjectsPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/werk/:projectNo/berichten",
        Component: (props) => (
            <ProjectLayout {...props}>
                <MessagesPage isFullWidth={true} {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo, apps.constructionQuality],
        exact: true
    },
    {
        path: "/werk/:projectNo/standaard-opties",
        Component: (props) => (
            <ProjectLayout {...props}>
                <StandardOptionsConfigPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: ["/werk/:projectNo/:viewType(dossier|building)", "/werk/:projectNo/:viewType(dossier|building)/:selectedDataId"],
        Component: (props) => (
            <ProjectLayout {...props}>
                <DossiersPage {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/dossier/:dossierId",
        Component: DossierRedirect,
        permissions: [apps.huisinfo],
        exact: false
    },
    {
        path: "/werk/:projectNo/deadline-dossier",
        Component: (props) => (
            <ProjectLayout {...props}>
                <DossierDeadline {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/contract",
        Component: (props) => (
            <ProjectLayout {...props}>
                <ContractOverview />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/contract/:contractId",
        Component: (props) => (
            <ProjectLayout {...props}>
                <Contract {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
   
    {
        path: "/werk/:projectNo/deadline-dossier/details",
        Component: (props) => (
            <ProjectLayout {...props}>
                <DossierDeadlineDetails {...props} />
            </ProjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/object/:buildingNoInternal+/berichten",
        Component: (props) => (
            <ObjectLayout {...props}>
                <MessagesPage isFullWidth={true} selectedBuildingOnly={true}  {...props} />
            </ObjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/object/:buildingNoInternal+",
        Component: (props) => (
            <ObjectLayout {...props}>
                <BuildingOverviewPage {...props} />
            </ObjectLayout>
        ),
        permissions: [apps.huisinfo],
        exact: true
    },
    {
        path: "/settings/WB-Werken",
        Component: (props) => (
            <SettingsLayout {...props}>
                <WbProjects {...props} />
            </SettingsLayout>
        ),
        permissions: [apps.settingModule],
        exact: true
    },
    {
        path: "/settings/link-email",
        Component: (props) => (
            <SettingsLayout {...props}>
                <EmailLinkLists {...props} />
            </SettingsLayout>
        ),
        permissions: [apps.settingModule],
        exact: true
    },
    {
        path: "/settings/bibliotheek",
        Component: (props) => (
            <SettingsLayout {...props}>
                <DrawingLocationsNames {...props} />
            </SettingsLayout>
        ),
        permissions: [apps.settingModule],
        exact: true
    },
    {
        path: "/settings/import",
        Component: (props) => (
            <SettingsLayout {...props}>
                <ImportFilesForDataLoad {...props} />
            </SettingsLayout>
        ),
        permissions: [apps.settingModule],
        exact: true,
    },
    {
        path: "/settings/cuf-xml",
        Component: (props) => (
            <SettingsLayout {...props}>
                <ImportCufXmlList {...props} />
            </SettingsLayout>
        ),
        permissions: [apps.settingModule],
        exact: true,
    },
    {
        path: "/profile/:profileType",
        Component: Profile,
        permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality, apps.resolverModule, apps.settingModule],
        exact: true
    },
];

//WKB and OPNAME app routes
function opnameAppRoutes(...multiBasePaths) {
    return [
        {
            path: multiBasePaths.map(path => `${path}/toezichtsmomenten`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <SurveyMoments {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: true,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/opdrachten`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <AssignmentsGrid {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: true,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/opdrachten/opdracht/:assignmentId`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <AssignmentDetails {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: true,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/inspecties`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <Inspections {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: false,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/opleveringen`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <Deliveries {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: false,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/voorschouwingen`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <PreDeliveries {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: false,
            accessInOffline: true
        },
        {
            path: multiBasePaths.map(path => `${path}/melding/:repairRequestId`),
            Component: RepairRequestDetailsPage,
            permissions: [apps.aftercare],
            exact: true
        },
        {
            path: multiBasePaths.map(path => `${path}/tweede-handtekening`),
            Component: (props) => (
                <ProjectLayout {...props}>
                    <Signature {...props} />
                </ProjectLayout>
            ),
            permissions: [apps.huisinfo, apps.aftercare, apps.constructionQuality],
            exact: false,
            accessInOffline: true
        },
    ]
}

export const commonConstants = {
    GETALL_REQUEST: 'BUILDINGS_GETALL_REQUEST',
    GETALL_SUCCESS: 'BUILDINGS_GETALL_SUCCESS',
    GETALL_FAILURE: 'BUILDINGS_GETALL_FAILURE',
    
    GET_AVAILABLE_SURVEY_MODULES: 'GET_AVAILABLE_SURVEY_MODULES',
    GET_AVAILABLE_SURVEY_MODULES_LOADING: 'GET_AVAILABLE_SURVEY_MODULES_LOADING',

    CHANGE_SUCCESS: 'BUILDINGS_SELECT_SUCCESS',
    UPDATE_RIGHTS_SUCCESS: 'UPDATE_RIGHTS_SUCCESS',

    CHANGE_APP_SUCCESS: 'CHANGE_APP_SUCCESS',
    GET_CURRENT_VERSION_DATE: 'GET_CURRENT_VERSION_DATE',

    GETCOUNT_SUCCESS: 'GETCOUNT_SUCCESS',
    GETCOUNT_ERROR: 'GETCOUNT_ERROR',
    GETCOUNT_LOADING: 'GETCOUNT_LOADING',

    CHANGE_MELDINGEN_FILTER_SUCCESS: 'CHANGE_MELDINGEN_FILTER_SUCCESS',

    GET_WORK_ORDERS_COUNTS_LOADING: "GET_WORK_ORDERS_COUNTS_LOADING",
    GET_WORK_ORDERS_COUNTS_ERROR: "GET_WORK_ORDERS_COUNTS_ERROR",
    GET_WORK_ORDERS_COUNTS_SUCCESS: "GET_WORK_ORDERS_COUNTS_SUCCESS",
};

export const LOCAL_STORAGE_GRID_KEYS = {
    REPAIR_REQUEST_COLUMN_VISIBILITY: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_VISIBILITY"
    },
    REPAIR_REQUEST_COLUMN_SORT: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_SORT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_SORT"
    },
    REPAIR_REQUEST_COLUMN_FILTER: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_FILTER"
    },
    REPAIR_REQUEST_COLUMN_ORDER: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_ORDER"
    },
    REPAIR_REQUEST_COLUMN_WIDTH: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_WIDTH"
    },
    REPAIR_REQUEST_PAGINATION: {
        AFTERCARE: "REPAIR_REQUEST_PAGINATION_MODAL",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_PAGINATION"
    },
    REPAIR_REQUEST_SEARCH_TEXT: {
        AFTERCARE: "REPAIR_REQUEST_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_SEARCH_TEXT"
    },
    DASHBOARD_COLUMN_VISIBILITY : {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_VISIBILITY",
    },
    DASHBOARD_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_SORT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_SORT"
    },
    DASHBOARD_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_FILTER"
    },
    DASHBOARD_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_ORDER"
    },
    DASHBOARD_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_WIDTH"
    },
    DASHBOARD_PAGINATION: {
        AFTERCARE: "AFTERCARE_DASHBOARD_PAGINATION",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_PAGINATION"
    },
    DASHBOARD_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_DASHBOARD_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_SEARCH_TEXT"
    },
};
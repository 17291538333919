import React, {  useMemo } from 'react'
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { ChatMessages } from '../Chat/ChatMessages';
import { useTranslation } from 'react-i18next';

const RepairRequestMessages = ({ chatProps, updateParentState, title, isResponseRequiredBtnVisible, updateResponseRequired, refreshParentState, readOnlyChat }) => {
  const { t } = useTranslation();
  const isReadOnlyChat = useMemo(() => chatProps.selectedChat?.archived || chatProps.selectedChat?.isLocked || readOnlyChat, [chatProps])
 
  return (<Box bgcolor={grey[100]}>
      <Grid container style={{minHeight:380,height:'35vh',position:'relative'}} >
        <ChatMessages
          refreshParentState={refreshParentState}
          selectedChat={chatProps.selectedChat}
          chatBoxOpen
          updateParentState={updateParentState}
          chats={[chatProps.selectedChat]}
          useParantWidth
          chatStartList={[]}
          expandedList={[]}
          importantChats={chatProps.importantChats}
          isFilterByBuilding={false}
          selectedHasUpdate={chatProps.selectedHasUpdate}
          title={title}
          isReadOnlyChat={isReadOnlyChat}
          applyMinStyles
          hideBackBtn
        />
      </Grid>
      {isResponseRequiredBtnVisible && <Stack direction="row" alignItems="center">
          <FormControlLabel
              sx={{ margin: '0 0 -12px' }}
              control={
                  <Checkbox checked={!!chatProps.selectedChat?.isResponseRequired} disabled={isReadOnlyChat} onChange={() => updateResponseRequired(!chatProps.selectedChat?.isResponseRequired)} />
              }
              label={t("repairRequest.chatResponseRequired")}
          />
      </Stack>}
    </Box>)
 
}

export default RepairRequestMessages